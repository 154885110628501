/**
 * @deprecated
 * This is old popup.js
 * Provide shared API for create popup in site template code
 * leave it here for Backward compatibility
 * */
//Reporter functions start
function hideAppletLoader() {
    document.body.removeChild(document.getElementById('bgdiv'));
}

function replaceContent() {
    prepic = document.createElement('div');
    prepic.setAttribute('id', 'app');
    prepic.setAttribute('style', 'width:0px; height:0px');

    document.body.appendChild(prepic);
    var bgdiv = document.createElement('div');
    bgdiv.setAttribute('id', 'bgdiv');
    bgdiv.setAttribute("style", 'position: fixed; top: 0; left: 0; overflow: hidden; padding: 0; margin: 0; background-color: #000; filter: alpha(opacity=60); opacity: 0.5; display: block; z-index: 1000; width: 100%; height: 100%');
    bgdiv.style.cssText = 'position: fixed;  top: 0; left: 0; overflow: hidden; padding: 0; margin: 0; background-color: #000; filter: alpha(opacity=60); opacity: 0.5; display: block; z-index: 1000; width: 100%; height: 100%';
    bgdiv.innerHTML = '<img src="/images/ajax-loader.gif" style="position:absolute; top:47%; left:47%; margin:auto; display:block"/>';
    document.body.appendChild(bgdiv);

    var appcode = '<applet name="Exolog debug tool" id="exologBugReport" code="exolog/dtool/DTool.class" archive="/modules/java/dtool.jar" border="0" width="1" height="1" >';
    appcode = appcode + '<PARAM NAME="useragent"    VALUE="' + navigator.userAgent + '">';
    appcode = appcode + '<PARAM NAME="curpage"    VALUE="' + location.href + '">';
    appcode = appcode + '<PARAM NAME="refpage"    VALUE="' + document.referrer + '">';
    appcode = appcode + '</applet>';
    if (document.getElementById("exologBugReport")) {
        document.getElementById("app").innerHTML = '<div id="app"></div>';
        document.getElementById("app").innerHTML = appcode;
    } else {
        document.getElementById("app").innerHTML = appcode;
    }
}

var isCtrl = false;
document.onkeyup = function (e) {
    isCtrl = false;
}

document.onkeydown = function (e) {
    if (!e) e = window.event;
    if (e.keyCode == 191 && isCtrl == true) {
        replaceContent();
        isCtrl = false;
        return false;
    }
    if (e.keyCode == 17) isCtrl = true;
}
//Reporter functions end


function popUp(link, u_w, u_h, show_control, popup_title) {

    var o = this;
    this.link = link;
    this.u_w = parseInt(u_w);
    this.u_h = parseInt(u_h);
    this.show_control = show_control;
    var popdiv;
    var bgdiv;
    var popupdiv_close;
    var iframe;
    var eval_js = false;
    var mobile = false;
    //param for start function when iframe load
    var iframe_load = false;
    //param if content img
    var iframe_marg = false;
    //check for mobile device

    var uagent = navigator.userAgent.toLowerCase();
    if (uagent.search("series60") > -1 || uagent.search("symbian") > -1 || uagent.search("android") > -1 || uagent.search("iphone") > -1 || uagent.search("blackberry") > -1 || uagent.search("opera mini") > -1 || uagent.search("opera mobi") > -1 || uagent.search("windows mobile") > -1) {
        mobile = true;
    }

    this.closediv = function () {
        if (this.bgdiv && this.popdiv) {
            document.body.removeChild(this.bgdiv);
            document.body.removeChild(this.popdiv);
            this.popdiv = null;
            this.bgdiv = null;
            if (onClose) {
                runRedir(onClose);
            }
        }
    }

    this.windowsize = function () {
        var winWidth = 0, winHeight = 0;
        if (typeof (window.innerWidth) == 'number') {
            //Non-IE
            let dscroll = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
            let pre_w = document.body.clientWidth;
            document.body.style.overflow = dscroll;
            let w_scroll = pre_w - document.body.clientWidth;
            winWidth = window.innerWidth - w_scroll;
            winHeight = window.innerHeight;
        } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in 'standards compliant mode'
            winWidth = document.documentElement.clientWidth;
            winHeight = document.documentElement.clientHeight;
        } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            winWidth = document.body.clientWidth;
            winHeight = document.body.clientHeight;
        }


        return {width: winWidth, height: winHeight};
    }

    this.keyhandle = function (ev) {
        ev = ev || event || document.getElementById('poupdiv_container').contentWindow.event;
        if (ev.keyCode == 27) {
            this.closediv();
            return false;
        }
    }

    this.resize = function () {
        if (this.popdiv) {
            if ((this.windowsize().height > this.u_h + 20 && this.windowsize().width > this.u_w + 20) || mobile) {
                var h = this.u_h;
                var w = this.u_w;
            } else {
                if (this.windowsize().height < this.u_h + 20 && this.windowsize().width > this.u_w + 20) {
                    var h = Math.round(this.windowsize().height - 40);
                    var w = Math.round(h * this.u_w / this.u_h);
                } else if (this.windowsize().height > this.u_h + 20 && this.windowsize().width < this.u_w + 20) {
                    var w = Math.round(this.windowsize().width - 40);
                    var h = Math.round(w * this.u_h / this.u_w);
                } else {
                    var w = Math.round(this.windowsize().width - 40);
                    var h = Math.round(w * this.u_h / this.u_w);
                    if (h > this.windowsize().height) {
                        var h = Math.round(this.windowsize().height - 40);
                        var w = Math.round(h * this.u_w / this.u_h);
                    }
                }
            }
            if (!mobile) {
                var popleft = Math.round(this.windowsize().width / 2) - Math.round(w / 2);
                var poptop = Math.round(this.windowsize().height / 2) - Math.round((h) / 2);
            } else {
                var popleft = 50;
                var poptop = 40
            }


            this.popdiv.style.height = h + 'px';
            this.popdiv.style.width = w + 'px';
            this.popdiv.style.top = poptop + 'px';
            this.popdiv.style.left = popleft + 'px';
        }
        if (this.popupdiv_close) {
            this.popupdiv_close.style.top = (1 * (poptop - this.popupdiv_close.offsetHeight)) + 'px';
            this.popupdiv_close.style.left = (1 * (popleft + w - this.popupdiv_close.offsetWidth)) + 'px';
        }
        if (this.iframe) {
            this.iframe.style.height = (h - (this.show_control ? 12 : 0)) + 'px';
            this.iframe.style.width = (w - (this.show_control ? 12 : 0)) + 'px';
        }
        if (iframe_marg) {
            this.imageResize()
        }
        ;
    }

    this.imageResize = function () {
        if (iframe_load && o.iframe.contentWindow) {
            var imgs = o.iframe.contentWindow.document.getElementsByTagName("img");
            if (imgs.length == 1) {
                var tow = parseInt(o.iframe.style.width);
                var toh = parseInt(o.iframe.style.height);
                for (var p = 0; p < imgs.length; p++) {
                    var iw = parseInt(imgs[p].width);
                    var ih = parseInt(imgs[p].height);
                    var nW = tow;
                    var nH = toh;
                    if (iw > ih) {
                        nH = (ih / (iw / tow)) - 2;
                    }
                    if (ih > iw) {
                        nW = (iw / (ih / toh)) - 2;
                    }
                    imgs[p].style.width = nW + 'px';
                    imgs[p].style.height = nH + 'px';
                }
            }
        }
    }


    // by default we need true, we cannot use || trick here becouse 2 param false
    if (typeof this.show_control == "undefined") {
        this.show_control = true;
    }
    if (!this.show_control) {
        var popupdivclass = 'donotshow';
    }
    if (typeof this.show_control == "object") {
        var popupdivclass = this.show_control['div'];
        var popupbuttonclass = this.show_control['button'];
        var onClose = this.show_control['onClose'];
    }

    this.popdiv = document.createElement('div');
    this.popdiv.setAttribute('id', 'popupdiv');
    if (popupdivclass) {
        this.popdiv.className = popupdivclass;
    }
    // check that 1 param is URL
    if (this.link.substr(0, 1) == '?' || this.link.substr(0, 1) == '/' || this.link.substr(0, 4) == 'http') {
        this.iframe = document.createElement('iframe');
        this.iframe.setAttribute('src', this.link);
        this.iframe.setAttribute('allowtransparency', 'true');
        this.iframe.setAttribute('frameBorder', '0');
        this.iframe.setAttribute('border', '0');
        this.iframe.setAttribute('id', 'poupdiv_container');
        if ((this.link.indexOf("/file") > -1) || (this.link.indexOf("?file=") > -1) || (this.link.indexOf("/cache/") > -1)) {
            iframe_marg = true;
        }
    } else {
        this.iframe = document.createElement('div');
        this.iframe.innerHTML = this.link;
        eval_js = true;
    }

    if (popup_title) {
        this.popupdiv_header = createElementFromHTML('<div class="exo-modal__header">\n' +
            '            <a href="javascript:closediv();" class="exo-modal__header-back" aria-label="Close">\n' +
            '                <i class="efa efa-angle-left efa-2x" aria-hidden="true"></i>\n' +
            '            </a>\n' +
            '            <h3>' + popup_title + '</h3>\n' +
            '</div>')

        this.popdiv.appendChild(this.popupdiv_header);

        //if we show header => hide control
        this.show_control = false;

    }

    if (this.show_control !== false) {
        // init top top-coltrol bar
        this.popupdiv_close = document.createElement('a');
        this.popupdiv_close.setAttribute('id', 'popupdiv_close');
        this.popupdiv_close.setAttribute('href', 'javascript:closediv();');
        if (popupbuttonclass) {
            this.popupdiv_close.className = popupbuttonclass;
        }
    }


    //background pop_up
    this.bgdiv = document.createElement('div');
    this.bgdiv.setAttribute('id', 'bgdiv');
    this.bgdiv.setAttribute("style", 'position: fixed; top: 0; left: 0; overflow: hidden; padding: 0; margin: 0; background-color: #000; filter: alpha(opacity=60); opacity: 0.5; display: block; z-index: 1000; width: 100%; height: 100%');
    this.bgdiv.style.cssText = 'position: fixed;  top: 0; left: 0; overflow: hidden; padding: 0; margin: 0; background-color: #000; filter: alpha(opacity=60); opacity: 0.5; display: block; z-index: 1000; width: 100%; height: 100%';


    document.body.appendChild(this.bgdiv);
    document.body.appendChild(this.popdiv);

    if (this.show_control) {
        this.popdiv.appendChild(this.popupdiv_close);
    }
    this.resize();

    this.popdiv.appendChild(this.iframe);
    this.resize();

    // eval JS scripts for all browsers exept Firefox and non url request
    if (eval_js && !/Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
        var scripts = this.iframe.getElementsByTagName("script");
        var script;
        for (var i = 0; script = scripts[i]; i++) {
            eval(script.innerHTML);
        }
    }

    // init events
    //eventPush(window,'keydown',function (event) {o.keyhandle(event); });
    document.onkeydown = function (event) {
        o.keyhandle(event)
    };
    if (!mobile) {
        window.onresize = function () {
            o.resize()
        }
    }

    eventPush(o.iframe, 'load', function () {
        o.iframe.contentWindow.document.onkeydown = function (event) {
            o.keyhandle(event)
        };
    });
    if (iframe_marg) eventPush(o.iframe, 'load', function () {
        o.iframe.contentWindow.document.body.style.cssText = 'padding: 0; margin: 0;';
        iframe_load = true;
        o.imageResize()
    });
}

function eventPush(obj, event, handler) {
    if (obj.addEventListener) {
        obj.addEventListener(event, handler, false);
    } else if (obj.attachEvent) {
        obj.attachEvent('on' + event, handler);
    }
}

let od, popup;

function closediv() {
    if (typeof (od) == "object") {
        od.closediv();
    }
}

function openDiv(link, w, h, show_control, popup_title) {
    od = new popUp(link, w, h, show_control, popup_title);
}

function imgWin(imgObj) {
    if (imgObj.src && imgObj.w && imgObj.h) {
        openDiv(imgObj.src, imgObj.w, imgObj.h, undefined, imgObj.name);
    }
    return false;
}

function openPopup(link, w, h) {
    let popleft = Math.round(screen.availWidth / 2) - Math.round(w / 2);
    let poptop = Math.round(screen.availHeight / 2) - Math.round(h / 2);
    popup = window.open(link, 'popup_window', 'width=' + w + ',height=' + h + ',location=0,status=1,toolbar=0,resize=0,scrollbars=1,top=' + poptop + ',left=' + popleft);
    popup.focus();
}

function createCSS(selector, declaration) {
    // test for IE
    var ua = navigator.userAgent.toLowerCase();
    var isIE = (/msie/.test(ua)) && !(/opera/.test(ua)) && (/win/.test(ua));

    // create the style node for all browsers
    var style_node = document.createElement("style");
    style_node.setAttribute("type", "text/css");
    style_node.setAttribute("media", "screen");

    // append a rule for good browsers
    if (!isIE) style_node.appendChild(document.createTextNode(selector + " {" + declaration + "}"));

    // append the style node
    document.getElementsByTagName("head")[0].appendChild(style_node);

    // use alternative methods for IE
    if (isIE && document.styleSheets && document.styleSheets.length > 0) {
        var last_style_node = document.styleSheets[document.styleSheets.length - 1];
        if (typeof (last_style_node.addRule) == "object") last_style_node.addRule(selector, declaration);
    }
};

function runRedir(url) {
    // default is standart openDiv()
    var parent_link = parent;

    // search for openPopup
    if (window.opener != null) {
        parent_link = window.opener;
        window.close();
    } else {
        // trying to close standart div popup

        if (typeof (parent.closediv) == "function") {
            parent.closediv();
        }

        if (parent != null && parent.hs != null && typeof (parent.hs.close) == "function") {
            parent.hs.close();
        }

    }


    if (url == 'refresh' || url.indexOf('reload') > -1) {


        if (typeof (parent_link.document.forms[0]) !== "undefined" && parent_link.document.forms[0].elements['activetab'] != null && parent_link.document.forms != null) {
            let activetab = parent_link.document.forms[0].elements['activetab'].value;

            if (url == 'reload_nocache') {
                parent_link.location = parent_link.location + '&activetab=' + activetab + '&caching=off';
            } else {
                parent_link.location = parent_link.location + '&activetab=' + activetab;
            }

        } else if (url == 'reload_nocache') {
            parent_link.location = genURLVars(parent_link.location, {'caching': 'off'});
        } else if (url.indexOf('reload(') > -1) {
            var add_data = eval(url.replace('reload', ''));
            parent_link.location = genURLVars(parent_link.location, add_data);
        } else {
            parent_link.location.reload(true);
        }
    } else {
        setParentURL(parent_link, url);
    }
}

function setParentURL(parent_link, url) {
    // check if URL start from 'http'
    if (url.indexOf('http') !== 0) {
        var url = "" + parent_link.location.protocol + "//" + parent_link.location.host + url;
    }
    parent_link.location.replace(url);
}

function genURLVars(query_string, add_data) {
    params = new Array();
    if (typeof (add_data) != 'object') {
        add_data = {};
    }

    var anchorURL = query_string.toString().split("#");

    if (anchorURL[1] != null) {
        anchorURL = "#" + anchorURL[1];
        add_data.rand = Math.random();
    } else {
        anchorURL = "";
    }
    let loc_split = query_string.search.replace('?', '').split('&');

    for (var i = 0; i < loc_split.length; i++) {

        let ps = loc_split[i].toString().split('=');
        if (typeof (ps[1]) != "undefined") {
            params[ps[0]] = ps[1];

        }
    }

    for (i in add_data) {
        params[i] = add_data[i].toString();
    }


    let newURL = query_string.protocol + '//' + query_string.hostname + query_string.pathname;
    let flag = '?';
    for (i in params) {
        if (typeof (params[i]) != 'function') {
            newURL += flag + i + '=' + params[i];
            flag = '&';
        }

    }
    newURL += anchorURL;
    return newURL;

}

function createElementFromHTML(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
}

window.hideAppletLoader = hideAppletLoader
window.popUp = popUp
window.closediv = closediv
window.openDiv = openDiv
window.imgWin = imgWin
window.openPopup = openPopup
window.runRedir = runRedir

export {
    hideAppletLoader,
    popUp,
    closediv,
    openDiv,
    imgWin,
    openPopup,
    runRedir
}

